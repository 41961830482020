import React, { useMemo } from 'react'

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// project import
import Palette from './palette'
import CustomShadows from './shadows'
import componentsOverride from './overrides'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import { TypographyOptions } from '@mui/material/styles/createTypography'

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }: { children: React.ReactNode }) {
    const theme = Palette('light')

    const themeTypography: TypographyOptions = {
        htmlFontSize: 16,
        fontFamily: `'Public Sans', sans-serif`,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontWeight: 600,
            fontSize: '2.375rem',
            lineHeight: 1.21,
        },
        h2: {
            fontWeight: 600,
            fontSize: '1.875rem',
            lineHeight: 1.27,
        },
        h3: {
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.33,
        },
        h4: {
            fontWeight: 600,
            fontSize: '1.25rem',
            lineHeight: 1.4,
        },
        h5: {
            fontWeight: 600,
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        h6: {
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.57,
        },
        caption: {
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.66,
        },
        body1: {
            fontSize: '0.875rem',
            lineHeight: 1.57,
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: 1.66,
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: 1.57,
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: 1.66,
        },
        overline: {
            lineHeight: 1.66,
        },
        button: {
            textTransform: 'capitalize',
        },
    }

    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

    interface CustomThemeOptions extends ThemeOptions {
        customShadows: typeof themeCustomShadows
    }

    const themeOptions = useMemo(
        (): CustomThemeOptions => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1440,
                },
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8,
                },
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography,
        }),
        [theme, themeTypography, themeCustomShadows]
    )

    const themes = createTheme(themeOptions)
    themes.components = componentsOverride(themes)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
