import { Outlet } from 'react-router-dom'

// ==============================|| MINIMAL LAYOUT ||============================== //

export default function MinimalLayout() {
    return (
        <>
            <Outlet />
        </>
    )
}
