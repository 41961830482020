// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

import { Theme } from '@mui/material'

export default function InputLabel(theme: Theme) {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[600],
                },
                outlined: {
                    lineHeight: '1rem',
                    top: -4,
                    '&.MuiInputLabel-sizeSmall': {
                        lineHeight: '1em',
                    },
                    '&.MuiInputLabel-shrink': {
                        background: theme.palette.background.paper,
                        padding: '0 8px',
                        marginLeft: -6,
                        top: 2,
                        lineHeight: '1rem',
                    },
                },
            },
        },
    }
}
