import { Outlet } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div>
            {/* Your dashboard layout components (navbar, sidebar, etc.) */}
            <main>
                <Outlet /> {/* This is where the child routes will render */}
            </main>
        </div>
    );
};

export default Dashboard; 